import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Button } from "react-bootstrap";
import PaymentMethodModal from './PaymentMethodModal';
import toast from 'react-hot-toast';
import { CardFixedTop } from "../../../utils";
import { Spin } from 'antd';

function PaymentMethod() {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [editPaymentMethod, setEditPaymentMethod] = useState(null);
    const [loader, setLoader] = useState("false");

    const fetchPaymentMethods = () => {
        setLoader(true);
        axios.get('/api/payment-methods').then((res) => {
            if (res["data"].status == "1") {
                paymentMethods(res["data"].data);
            }
            setLoader(false);
        });
    };

    const handleAdd = () => {
        setEditPaymentMethod(null);
        setModalShow(true);
    };

    const handleEdit = (paymentMethod) => {
        setEditPaymentMethod(paymentMethod);
        setModalShow(true);
    };

    const handleSave = async (data) => {
        if (data.id) {
            await axios.put(`/api/payment-methods/${data.id}`, data);
            toast.success('Payment Gateway Edited successfully');
        } else {
            await axios.post('/apipayment-methods', data);
            toast.success('Payment Gateway Added successfully');
        }
        fetchPaymentMethods();
        setModalShow(false);
    };

    return (
        <>
            <CardFixedTop title={PaymentMethod}>
                <div className="float-end">
                    <Button onClick={handleAdd} className="mb-3">Add PaymentMethod</Button>
                </div>
            </CardFixedTop>
            <Card>
                <Card.Body>
                    <Spin spinning={loader}>
                        <div className="tableFixHead" style={{ height: "220px" }}>
                            <table className="table-sm flex">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentMethods.map((method) => (
                                        <tr key={method.id}>
                                            <td>{method.id}</td>
                                            <td>{method.name}</td>
                                            <td>{method.status}</td>
                                            <td>
                                                <Button variant="warning" onClick={() => handleEdit(method)}>Edit</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Spin>
                </Card.Body>
                <PaymentMethodModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onSave={handleSave}
                    paymentMethod={editPaymentMethod}
                />
            </Card>
        </>
    );
}

export default PaymentMethod;
