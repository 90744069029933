import React, { useEffect } from "react";
import { CardFixedTop } from "../../../utils";
import SalesList from "../../../components/Dashboard/salesList";
import TodaySales from "../../../components/Dashboard/todaySales";
import TotalUsers from "../../../components/Dashboard/TotalUsers";
import TotalProducts from "../../../components/Dashboard/Receipt/TotalProducts";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className="container">
                <CardFixedTop title="Dashboard">
                    <div className="float-start">
                    </div>
                </CardFixedTop>
                <Row className="mb-3">
                    <Col xs="12" sm="6" lg="3" className="mt-3">
                        <TodaySales />
                    </Col>
                    <Col xs="12" sm="6" lg="3" className="mt-3">
                        <TotalUsers />
                    </Col>
                    <Col xs="12" sm="6" lg="3" className="mt-3">
                        <TotalProducts />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <SalesList />
                </Row>
            </div>
        </div>
    )
}

export default Dashboard;