import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function PaymentMethodModal({ show, onHide, onSave, paymentMethod }) {
    const [name, setName] = useState('');
    const [status, setStatus] = useState('inactive');

    useEffect(() => {
        if (paymentMethod) {
            setName(paymentMethod.name);
            setStatus(paymentMethod.status);
        } else {
            setName('');
            setStatus('inactive');
        }
    }, [paymentMethod]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = { id: paymentMethod?.id, name, status };
        onSave(data);
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{paymentMethod ? 'Edit' : 'Add'} Payment Method</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter payment method name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formStatus">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as="select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option value="inactive">Inactive</option>
                            <option value="active">Active</option>
                        </Form.Control>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-3">
                        Save Changes
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default PaymentMethodModal;
