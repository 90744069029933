import React from 'react';
import { Card ,CardBody } from 'react-bootstrap';
import { MdShoppingCart } from 'react-icons/md';


const TotalProducts = () => {
    return (
        <>
            <Card className="text-white bg-blueviolet ">
                <CardBody className="pb-0">
                    <i style={{ float: "right", fontSize: "50px" }}> <MdShoppingCart/> </i>
                    <h4 className="mb-0">TotalProducts</h4>
                    <p>1,255</p>
                </CardBody>

            </Card>
        </>
    );
}

export default TotalProducts;
