
export const  appTittle = "TeamDD";

export function capitalizeFirst(str) {
    if (str == "" || str == null || str == " ") {
        return "";
    }

    str = str.toLowerCase();

    return str.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
    });
}
//UpperCaseFunction
export function upperCase(str) {
    if (str == "" || str == null || str == " " || str.length < 1) return "";
    return str.toString().toUpperCase();
}
// lowerCaseFunction
export function lowerCase(str) {
    if (str == "" || str == null || str == " ") return "";
    return str.toString().toLowerCase();
}
// SearchFunction
export function queryParam(search = "") {
    let rv = [];
    if (search.length < 1) {
        return rv;
    }
    let str = search; 
    str = str.replace(/\?/g, "");
    let ar = str.split("&");
    if (ar && ar.length > 0) {
        ar.map((item, i) => {
            let m = item.split("=");
            rv[m[0]] = m[1];
        });
    }
    return rv;
}
// FindCommonElement
export function findCommonElement(array1, array2) {

  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      if (upperCase(array1[i]) === upperCase(array2[j])) {
        return true;
      }
    }
  }
}
/* setLocalStorage */
export function setLocalStorage(key,value){
  localStorage.setItem(key, value);
}

/* getLocalStorage */
export function getLocalStorage(key){
  return localStorage.getItem(key) || false;
}

export function removeBothSlash(str) {
  return str.replace(/^\/|\/$/g, "");
}
//PrintDocument//

export const printDocument = (printId) => {
    var content = document.getElementById(printId);
    var pri = document.getElementById("print_frame").contentWindow;
    pri.document.open();
    pri.document.write(
      '<PrintWithWatermark/>',
      '<style>@font-face {font-family: "Bookman Old Style";src: local("Bookman Old Style"), url("fonts/bookold.woff") format("woff");}.font-bookman {font-family: "Bookman Old Style", sans-serif;font-size:12px;}</style>'
    );
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };


  //CardFixTop//
  export const CardFixedTop = ({ title, breadcrumb, children }) => {
    return (
      <div className="card card-fixed-top">
        <div className="card-body">
          <div className="d-flex  justify-content-between">
            <div style={{ padding: "2px 0 0 8px" }}>
            </div>
            <div>{children}</div>
          </div>
        </div>
      </div>
   );
};


export const DEVELOPER = {
    COMPANY_NAME: "DangerDrivers Mods",
    COMPANY_URL: "https://dangerdrivers.blogspot.com/",
    APP_NAME: "DangerDrivers",
    APP_VERSION: "1.0",
    SUPPORT_MOBILE: "+91 8015272347",
    SUPPORT_EMAIL: "mhnprashath@gmail.com",

};

