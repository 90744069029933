import React,{useEffect,useState} from 'react';
import { Card, CardBody } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { FaUser } from "react-icons/fa";
import axios from 'axios';


const TotalUsers = () => {
    const [userCount, setUserCount] = useState(null);

    useEffect(() => {
        axios.get('/api/admin')
            .then(response => {
                userCount(response.data.count);
            })
            .catch(err => {
                toast.error('Failed to fetch user count');
                console.error(err);
            });
    }, []);
    return (
        <>
            <Card className="text-white bg-pink ">
                <CardBody className="pb-0">
                    <i style={{ float: "right", fontSize: "50px" }}> <FaUser /> </i>
                    <h4 className="mb-0">TotalUsers</h4>
                    {userCount !== null ? <p>{userCount}</p> : <p>0</p>}
                </CardBody>

            </Card>
        </>
    );
}

export default TotalUsers;
