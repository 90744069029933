import React ,{ useState,useEffect }from 'react';
import { JWT_TOKEN } from '../utils/data';
import { getLocalStorage } from '../utils';
import PsContext from "./index";
import { Spinner } from "react-bootstrap";

const PsContextProvider = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const breakpoint = 620;
    // const [logged, setLogged] = useState(checkUserLogged());
    const [loader, setLoader] = useState(false);

    const Token = JWT_TOKEN;
    useEffect(() => {
        window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
      }, []);
    
      const isMobileView = () => (windowWidth < breakpoint ? true : false);
     
    //   const checkUserLogged = () => {
    //     return getLocalStorage("coa_logged") || "no";
    //   };


      return(
        <PsContext.Provider
        value={{
            // logged: logged,
            isMobileView :isMobileView,
            // checkUserLogged : checkUserLogged
        }}>
            {loader && (
        <div className="ps__loader">
          <div className="ps__loader__body">
            <div className="ps__loader__content">
              <Spinner animation="grow" size="lg" />
              <br />
              Please Wait
            </div>
          </div>
        </div>
      )}

      {props.children}
        </PsContext.Provider>
      )

}

export default PsContextProvider;
