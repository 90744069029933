import React ,{ useEffect, useState } from "react";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import AppRouter from "./routes/routes"; 
import PsContextProvider from "./context/PsContextProvider";
import 'antd/dist/reset.css'; 
function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

    if (isMobileView()) {
      document.getElementsByTagName("body")[0].style.background = "#fbfbfb";
    }
  }, []);
  const isMobileView = () => (windowWidth < breakpoint ? true : false);


  const toasterOptions = () =>{
    return{
      style: {
       
        duration: 100000,
        borderRadius: "4px",
        background: "rgba(214, 218, 200, 1)",
        color: "#222830",
      },
    }


  }

  return (
    <div>
    <PsContextProvider>
    <Toaster 
    toastOptions={toasterOptions()}
    position="top-center"
    reverseOrder={true}
  >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== "loading" && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{
                      background: "transparent",
                      border: "0px",
                      color: "#918f8f",
                    }}
                  >
                  X
                  </button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <div className="content">
      
      </div>
      <AppRouter />
      <iframe
        name="print_frame"
        id="print_frame"
        width="0"
        height="0"
        frameBorder="0"
        src="about:blank"
      ></iframe>
      </PsContextProvider>
    </div >
  );
}

export default App;
