import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import {  Card, Button } from "react-bootstrap";
import { FaPrint } from "react-icons/fa";
import axios from 'axios';


const AdminList = () => {
    const [loader, setLoader] = useState("false");
    
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get('https://teamdd.shop/user')
            .then(response => {
                setUsers(response.data);
                setLoader(false);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
                setLoader(false);
                
            }
            
        );
    }, []);

    return (
        <>
            <Card>
                <Card.Header className="fw-bold" >
                    Admin List
                    <div className="float-end">
                        <Button>
                            Print <a><FaPrint /></a>
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                <Spin spinning={loader}>
                    <div className="tableFixHead" style={{ height: "220px" }}>
                        <table className="table-sm flex">
                            <thead>
                                <tr>
                                    <th width="35">S.No</th>
                                    <th width="100">UserName</th>
                                    <th width="100">Password</th>
                                    <th width="100">Mobile</th>
                                    <th width="80">Age</th>
                                    <th width="60">Gender</th>
                                    <th width="40">Role</th>
                                </tr>
                            </thead>
                            <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.username}</td>
                            <td>{user.password}</td>
                            <td>{user.phone}</td>
                            <td>{user.gender}</td>
                            <td>{user.role}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
                    </div>
                    </Spin>
                </Card.Body>

            </Card>

        </>
    );
}

export default AdminList;
