import React from 'react';
import { Card ,CardBody } from 'react-bootstrap';
import { MdSell  } from 'react-icons/md';

const TodaySales = () => {
    return (
        <>
            <Card className="text-white bg-danger ">
                <CardBody className="pb-0">
                    <i style={{ float: "right", fontSize: "50px" }}> <MdSell/> </i>
                    <h4 className="mb-0">Today Sales</h4>
                    <p>512</p>
                </CardBody>

            </Card>
        </>
    );
}

export default TodaySales;
