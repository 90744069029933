import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Spin } from "antd";
import Layout from "../pages/app/Layout"
import Dashboard from "../pages/app/Dashboard";
import Settings from "../pages/app/Settings";
import PaymentMethod from "../pages/app/paymentMethods";

// Lazy load your components
const Login = React.lazy(() => import("../pages/Login"));
const ForgetPassword = React.lazy(() => import("../pages/ForgotPassword"));


const AppRouter = () => {
  return (
    <Router>
    <Suspense
        fallback={
          <Spin
            size="sm"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          />
        }
      >
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/forget-password" element={<ForgetPassword />} />
        
          <Route exact path="/app/dashboard" element={<Dashboard />} />
          <Route exact path="/app/pay" element={<PaymentMethod />} />

        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
